import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import datas from './assets/datas.json';

import Home from './components/home.jsx';
import Intro from './components/intro.jsx';
import Question from './components/question.jsx';
import End from './components/end.jsx';
import Contact from './components/contact.jsx';
import Thanks from './components/thanks.jsx';

class Questionnaire extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      questionnaireDatas: datas,
      currentStep: "home",
      prevStep: undefined,
      history: [],
      answers: []
    }

    this.resetQuestionnaire = this.resetQuestionnaire.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
  }

  addAnswer(questionId, answer) {

    this.setState(prevState => ({
      answers: {
        ...prevState.answers,
        [questionId]: answer
      }
    }));
  }

  nextStep(currentStepId, nextStepId) {
    let history = [...this.state.history];
    history.push({
      [currentStepId]: nextStepId
    });
    this.setState({
      history: history,
      currentStep: nextStepId,
    });
  }

  prevStep() {
    let history = [...this.state.history];

    if (history.length > 0) {
      const prevStep = history[history.length - 1];
      const prevStepId = Object.keys(prevStep)[0];
      history.pop();

      this.setState({
        history: history,
        currentStep: prevStepId,
      });
    }
  }

  resetQuestionnaire() {
    this.setState({
      history: [],
      currentStep: "home",
    });
  }

  displayItem(item, id) {
    switch(item.type) {
      case "home":
        return(
          <Home nextStep={(nextStepId) => this.nextStep(id, nextStepId)} />
        );
      case "intro":
        return(
          <Intro nextStep={(nextStepId) => this.nextStep(id, nextStepId)} />
        );
      case "question":
        return(
          <Question
            datas={item.datas}
            nextStep={(nextStepId) => this.nextStep(id, nextStepId)}
            resetQuestionnaire={() => this.resetQuestionnaire()}
            prevStep={() => this.prevStep()}
            id={id}
            addAnswer={(questionId, answer) => this.addAnswer(questionId, answer)}
            key={id}
          />
        );
      case "end":
        return(
          <End
            nextStep={(nextStepId) => this.nextStep(id, nextStepId)}
            resetQuestionnaire={() => this.resetQuestionnaire()}
          />
        );
      case "contact":
        return(
          <Contact
            answers={this.state.answers}
            resetQuestionnaire={() => this.resetQuestionnaire()}
            nextStep={(nextStepId) => this.nextStep(id, nextStepId)}
          />
        );
      case "thanks":
        return(
          <Thanks
            resetQuestionnaire={() => this.resetQuestionnaire()}
          />
        );
    }
  }

  render() {
    return(
      <div className="questionnaire-wrapper">
        {this.displayItem(this.state.questionnaireDatas[this.state.currentStep], this.state.currentStep)}
      </div>
    )
  }
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<Questionnaire />);
