import React from 'react';
import logo from '../assets/logo_MLR_Conseil.png';

import Answer from './answer.jsx';
import Popup from './popup.jsx';

class Question extends React.Component{

  constructor(props){
    super(props);

    scroll(0,0);

    this.state = {
      popupopened: false,
      validation: false,
      currentAnswer: undefined
    }

    this.togglePopup = this.togglePopup.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.setState({
      currentAnswer: value
    });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    this.setState({
      validation: true,
      currentAnswer: evt.target.answer.value
    });
  
    this.props.addAnswer(this.props.datas.questionid, evt.target.answer.value);
  }

  togglePopup() {

    this.setState(prevState => ({
      popupopened: !prevState.popupopened
    }));
  }

  render(){

    const answerList = this.props.datas.answers.map((item, i) => {
      return(
        <Answer key={i} id={"answer" + i} datas={item} validation={this.state.validation} currentAnswer={this.state.currentAnswer} handleChange={(value) => this.handleChange(value)} />
      );
    });

    return(

      <div className="question-wrapper">
        <form className="question section-inner" onSubmit={this.handleSubmit}>
          <div className="question-header">
            <button className="reset-questionnaire" onClick={() => this.props.resetQuestionnaire()}>Revenir au début</button>
            {/* <button className="questionnaire-reset" onClick={() => this.props.resetQuestionnaire()}>Revenir au début</button> */}
            <img src={logo} alt="logo MLR Conseil" />
          </div>
          <div className="question-body">
            <p className="question-number">Question {this.props.datas.questionnumber} / 4</p>
            <h2 className="question-title" dangerouslySetInnerHTML={{__html: this.props.datas.question}}></h2>
            <div className={this.state.currentAnswer != undefined ? 'question-answers selected' : 'question-answers'}>
              {answerList}
            </div>
          </div>
          <div className="question-footer">
            {this.state.validation == true ? <button className="popup-open" type="button" onClick={() => this.togglePopup()}><span className="info-icon"></span>Voir les explications</button> : ''}
            {
              this.state.validation == false ?
              <button className="button-primary" type="submit" disabled={this.state.currentAnswer != undefined ? false : true}>Valider votre réponse</button>
              :
              <button className="button-primary" type="button" onClick={() => this.props.nextStep(this.props.datas.nextstep)}>Continuer</button>
            }
          </div>
        </form>
        {this.state.popupopened == true ? <Popup togglePopup={this.togglePopup} text={this.props.datas.explications} /> : ''}
      </div>
    );
  }
}

export default Question
