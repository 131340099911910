import React from 'react';

class Popup extends React.Component{

  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="popup-wrapper">
        <div className="popup section-inner">
          <button className="close-button" onClick={() => this.props.togglePopup()}>Fermer<span className="close-icon"></span></button>
          <h3 className="popup-title">Explication :</h3>
          <p className="popup-text" dangerouslySetInnerHTML={{__html: this.props.text}}></p>
        </div>
      </div>
    );
  }
}

export default Popup

