import React from 'react';

class Input extends React.Component{

  /*
    Props
      type: type de champs
      name: Nom du champs
      label: Label
      isRequired: Champs requis
      defaultValue: Valeur par defaut
  */

  constructor(props){
    super(props);

    const dValue = this.props.defaultValue ? this.props.defaultValue : "";

    this.state = {
      dValue: dValue,
    }
  }

  render(){

    return(
      <div className="input-field-wrapper">
        <label htmlFor={this.props.name}>{this.props.label}{this.props.isRequired ? "*" : ""}</label>
        <input
          type={this.props.type ? this.props.type : "text"}
          name={this.props.name} id={this.props.name}
          required={this.props.isRequired}
          defaultValue={this.props.defaultValue}
          className="input-field"
          placeholder={this.props.placeholder}
          pattern={this.props.pattern}
        />
        {(this.props.errorText != null && this.props.errorText != 1) ? <span className="input-error-text">{this.props.errorText}</span> : "" }
      </div>
    );
  }
}

export default Input
