import React from 'react';
import astronaute from '../assets/astronaute.png';

class Intro extends React.Component{

  constructor(props){
    super(props);

    setTimeout(function () {
      props.nextStep("question1")
    }, 3000);
  }

  render(){
    return(
      <div className="intro">
        <div className="intro-body section-inner">
          <h1 className="intro-title">que la force soit avec vous</h1>
          <img className="intro-image" src={astronaute} alt="astronaute" />
        </div>
      </div>
    );
  }
}

export default Intro
