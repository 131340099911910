import React from 'react';
import logo from '../assets/logo_MLR_Conseil.png';
import astronautes from '../assets/astronautes.png';

class Thanks extends React.Component{

  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="thanks-wrapper">
        <div className="thanks section-inner">
          <div className="question-header">
              <img src={logo} alt="logo MLR Conseil" />
            </div>
          <div className="thanks-body">
            <h1 className="thanks-title">Merci !</h1>
            <p className="thanks-text">Nous avons bien reçu votre demande.<br />Nous prendrons contact avec vous prochainement.</p>
            <button className="button-primary" onClick={() => this.props.resetQuestionnaire()}>Commencer le quizz</button>
            <img className="thanks-image" src={astronautes} alt="astronautes" />
          </div>
        </div>
      </div>
    );
  }
}

export default Thanks
