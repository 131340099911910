import React from 'react';
import logo from '../assets/logo_MLR_Conseil.png';

class End extends React.Component{

  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="end-wrapper">
        <div className="end section-inner">
          <div className="question-header">
            <img src={logo} alt="logo MLR Conseil" />
          </div>
          <div className="end-body">
            <h1 className="end-title">C'est terminé !</h1>
            <p className="end-text">Souhaitez-vous être contacté concernant un projet futur ?<br />À la clé ? Vous serez peut-être tiré au sort pour gagner une prestation de 2 jours avec nos experts MLR Conseil.</p>
            <button className="button-primary" onClick={() => this.props.nextStep("contact")}>Envoyez vos coordonnées</button>
            <button className="button-secondary" onClick={() => this.props.resetQuestionnaire()}>Revenir au début</button>
          </div>
        </div>
      </div>
    );
  }
}

export default End
