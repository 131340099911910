import React from 'react';

function Answer(props) {
  
  let classes = "answer";
  let response = "";

  if (props.validation == true) {

    if (props.datas.correct == true) {
      classes = "answer correct";
      response = "Bravo ! Bonne réponse";
    } else {
      classes = "answer wrong";
      response = "Bien tenté, mais c’est raté...";
    }
  }

  return(
    <div className={classes}>
      <input type="radio" value={props.datas.answer} name="answer" id={props.id} disabled={props.validation == true ? true : false} onChange={() => props.handleChange(props.datas.answer)} required />
      <label htmlFor={props.id}>{props.datas.text}</label>
      {(props.currentAnswer == props.datas.answer && props.validation == true) ? <p className="answer-result">{response}</p> : ''}
    </div>
  );
}

export default Answer

