import React from 'react';
import logo from '../assets/logo_MLR_Conseil_Large.png';

class Home extends React.Component{

  constructor(props){
    super(props);
  }

  render(){
    return(
      <div className="home">
        <div className="home-body section-inner">
          <img src={logo} alt="logo MLR Conseil" />
          <h1 className="home-title">êtes-vous un pro de la démat. ?</h1>
          <p className="home-text">Tentez de répondre à 4 questions</p>
          <button className="button-primary button-large" onClick={() => this.props.nextStep("intro")}>Commencer</button>
        </div>
      </div>
    );
  }
}

export default Home
