{
  "home": {
    "type": "home"
  },
  "intro": {
    "type": "intro"
  },
  "end": {
    "type": "end"
  },
  "contact": {
    "type": "contact"
  },
  "thanks": {
    "type": "thanks"
  },
  "question1": {
    "type": "question",
    "datas": {
      "questionnumber": "1",
      "questionid": "question1",
      "question": "Quelles entreprises seront concernées par la réception de factures via le portail de l'État (Chorus) au 1er juillet 2024 ?",
      "answers": [
        {
          "text": "Les PME (petites et moyennes entreprises)",
          "answer": "reponse1",
          "correct": false
        },
        {
          "text": "Les établissements de taille intermédiaire",
          "answer": "reponse2",
          "correct": false
        },
        {
          "text": "Les grandes entreprises",
          "answer": "reponse3",
          "correct": false
        },
        {
          "text": "Toutes les entreprises",
          "answer": "reponse4",
          "correct": true
        }
      ],
      "explications": "Au 1er Juillet 2024, toutes les entreprises françaises sont concernées par la réception. C'est l'émission qui est étalée dans le temps. C'est pourquoi il est important de réfléchir dès à présent à la récupération des factures émises par les grandes entreprises.",
      "nextstep": "question2"
    }
  },
  "question2": {
    "type": "question",
    "datas": {
      "questionnumber": "2",
      "questionid": "question2",
      "question": "Les transactions concernées par le portail Chorus pour la partie e-invoicing sont&nbsp;:",
      "answers": [
        {
          "text": "Les factures échangées entre entités françaises uniquement",
          "answer": "reponse1",
          "correct": true
        },
        {
          "text": "Les factures échangées entre une entité française et une entité européenne non française",
          "answer": "reponse2",
          "correct": false
        },
        {
          "text": "Les factures échangées entre une entité française et le reste du monde",
          "answer": "reponse3",
          "correct": false
        },
        {
          "text": "Les factures échangées en intra-communautaire uniquement",
          "answer": "reponse4",
          "correct": false
        }
      ],
      "explications": "Seules les entités françaises sont concernées par le volet e-invoicing (transmission de factures). Cela signifie que des solutions de capture (type OCR avec IA par exemple) seront toujours utiles dans un contexte international. Sur le volet e-reporting, les échanges intracommunautaires seront également concernés.",
      "nextstep": "question3"
    }
  },
  "question3": {
    "type": "question",
    "datas": {
      "questionnumber": "3",
      "questionid": "question3",
      "question": "En mettant en place des solutions adaptées, je peux&nbsp;:",
      "answers": [
        {
          "text": "Gagner du temps en évitant de déposer et récupérer manuellement des documents sur le portail de l'état",
          "answer": "reponse1",
          "correct": true
        },
        {
          "text": "Automatiser l'extraction de données essentielles au sein de mes documents",
          "answer": "reponse2",
          "correct": true
        },
        {
          "text": "Automatiser des tâches de rapprochement et comptabilisation",
          "answer": "reponse3",
          "correct": true
        },
        {
          "text": "Responsabiliser et motiver mes équipes sur des tâches à plus forte valeur ajoutée",
          "answer": "reponse4",
          "correct": true
        }
      ],
      "explications": "Les projets de dématérialisation sont aussi l'occasion <br>- de réorganiser les tâches et responsabiliser les différents acteurs de vos flux <br>- de travailler sur les processus pour simplifier et automatiser des traitements de type Rapprochement / Comptabilisation / Workflow <br>- de réduire le temps et le coût de traitement (jusqu'à 85 % selon les flux et les volumes traités)",
      "nextstep": "question4"
    }
  },
  "question4": {
    "type": "question",
    "datas": {
      "questionnumber": "4",
      "questionid": "question4",
      "question": "MLR Conseil vous aide dans la dématérialisation depuis&nbsp;:",
      "answers": [
        {
          "text": "Moins de 3 ans",
          "answer": "reponse1",
          "correct": false
        },
        {
          "text": "Entre 3 et 5 ans",
          "answer": "reponse2",
          "correct": false
        },
        {
          "text": "Entre 5 et 10 ans",
          "answer": "reponse3",
          "correct": false
        },
        {
          "text": "Plus de 10 ans",
          "answer": "reponse4",
          "correct": true
        }
      ],
      "explications": "Nous accompagnons depuis 2010 les entreprises de toute taille (projets réalisés pour 10 000 à 2 000 000 de documents par an) à mettre en place des CSP sur la base de Core Model dans des environnements internationaux.",
      "nextstep": "end"
    }
  }
}
