import React from 'react';
import logo from '../assets/logo_MLR_Conseil.png';

import Input from './input.jsx';

class Contact extends React.Component{

  constructor(props){
    super(props);

    scroll(0,0);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      response: null
    }
  }

  handleSubmit(event){
    event.preventDefault();
    const formDatas = new FormData(event.target);
    const datas = {};

    formDatas.forEach(function(value, key){
      datas[key] = value;
    });

    console.log(formDatas);

    fetch('/ajax-form-submit.php', {
      method: 'POST',
      body: formDatas,
    }).then(response => {
      response.json().then(data =>{

        if (data.success === true) {
          this.props.nextStep("thanks");
        }

        this.setState({
          response: data
        });

        // gestion des erreurs
      })
    })
  }

  displayMessage() {
    if ( this.state.response != null) {
      return(
        <p className="form-response danger">{this.state.response.message}</p>
      );
    }
  }

  getError(value) {
    if (this.state.response) {
      if (this.state.response.success == false) {
        return this.state.response.state[value];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  render(){
    return(
      <div className="contact-wrapper">
        <div className="contact section-inner">
          <div className="question-header">
            <button className="reset-questionnaire" onClick={() => this.props.resetQuestionnaire()}>Revenir au début</button>
            <img src={logo} alt="logo MLR Conseil" />
          </div>
          <form className="contact-form" onSubmit={this.handleSubmit}>
            <input type="hidden" name="datas[reponses]" value={JSON.stringify(this.props.answers)} />
            <div className="form-columns">
              <div className="form-column">
                <Input
                  name="datas[nom]"
                  label="Nom"
                  isRequired={true}
                  errorText={this.state.response != null ? this.getError('nom'): null}
                />
              </div>
              <div className="form-column">
                <Input
                  name="datas[prenom]"
                  label="Prénom"
                  isRequired={true}
                  errorText={this.state.response != null ? this.getError('prenom'): null}
                />
              </div>
            </div>
            <div className="form-columns">
              <div className="form-column">
                <Input
                  name="datas[societe]"
                  label="Société"
                  isRequired={true}
                  errorText={this.state.response != null ? this.getError('societe'): null}
                />
              </div>
              <div className="form-column">
                <Input
                  name="datas[fonction]"
                  label="Fonction"
                  isRequired={true}
                  errorText={this.state.response != null ? this.getError('fonction'): null}
                />
              </div>
            </div>
            <div className="form-columns">
              <div className="form-column">
                <Input
                  name="datas[email]"
                  label="Email"
                  isRequired={true}
                  type="email"
                  errorText={this.state.response != null ? this.getError('email'): null}
                />
              </div>
              <div className="form-column">
                <Input
                  name="datas[telephone]"
                  label="Téléphone"
                  isRequired={false}
                />
              </div>

            </div>
            <div className="form-columns">
              <div className="form-column">
                <Input
                  name="datas[localisation]"
                  label="Localisation (code postal, ville...)"
                  isRequired={true}
                  errorText={this.state.response != null ? this.getError('codepostal'): null}
                />
              </div>
              <div className="form-column">
                <div className="select-field-wrapper">
                  <label htmlFor="echeance">Avez-vous un projet à échéance&nbsp;:*</label>
                  <select className="select-field" defaultValue={this.props.defaultValue} name="datas[echeance]" id="echeance" required={true}>
                    <option value="">- choisir une échéance -</option>
                    <option value="immédiate">immédiate</option>
                    <option value="3 à 6 mois">3 à 6 mois</option>
                    <option value="6 à 12 mois">6 à 12 mois</option>
                    <option value="+ d'un an">+ d'un an</option>
                    <option value="pas de projet">pas de projet</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="contact-form-rgpd">
              <input type="checkbox" name="datas[rgpd]" id="rgpd" required />
              <label htmlFor="rgpd">J'accepte que MLR Conseil recueille et utilise mes données afin de traiter ma demande de contact et d'assurer le suivi commercial.</label>
            </div>
            <div className="question-footer">
              <button className="button-primary">Envoyer</button>
            </div>
            {this.displayMessage()}
          </form>
        </div>
      </div>
    );
  }
}

export default Contact
